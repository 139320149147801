import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import styleConfig from '@/styles/style-config'

const HtmlContent = props => {
  return (
    <>
      <section className="html-content">
        <ReactMarkdown rehypePlugins={[rehypeRaw]} >{ props.content }</ReactMarkdown>
      </section>
      <style jsx>{
        `
          .html-content {
            padding-left: 24px;
            padding-right: 24px;
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            padding-left: 60px;
            padding-right: 60px;
          }
        `
      }</style>
    </>
  )
}

export default HtmlContent